import type { Question } from '@mentimeter/http-clients';
import update from 'immutability-helper';
import { core } from '@mentimeter/http-clients';
import {
  Channels,
  SeriesPrivateEvents,
  usePublish,
} from '@mentimeter/realtime';
import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import { questionsCacheKey, resultCacheKey } from './cache-keys';
import { useQuestionsMutate } from './use-questions';

interface AddPublicTemplate {
  templateId: number | undefined;
  context: string;
}
export interface AddPublicTemplateToNewSeriesArgs extends AddPublicTemplate {
  replaceQuestionId: string;
}
export interface AddPublicTemplateToOldSeriesArgs extends AddPublicTemplate {
  insertAtIndex: number;
}

export const usePublicTemplatesActions = (seriesId: string) => {
  const publish = usePublish({
    channel: Channels.SERIES_PRIVATE,
    value: seriesId,
  });
  const mutateQuestions = useQuestionsMutate(seriesId);
  const { mutate } = useSWRConfig();

  const addPublicTemplateToNewSeries = useCallback(
    async ({
      templateId,
      context,
      replaceQuestionId,
    }: AddPublicTemplateToNewSeriesArgs) => {
      const { data: response } = await core().publicTemplates.replace.post(
        seriesId,
        replaceQuestionId,
        {
          public_template_id: templateId,
          track_context: context,
        },
      );

      publish(SeriesPrivateEvents.UPDATE_NETWORK_CACHE, {
        cacheKey: questionsCacheKey(seriesId),
      });

      mutateQuestions(response.questions, {
        revalidate: true,
      });
      mutate<Question>(resultCacheKey(replaceQuestionId));

      return null;
    },

    [seriesId, publish, mutateQuestions, mutate],
  );

  const addPublicTemplateToOldSeries = useCallback(
    async ({
      insertAtIndex,
      templateId,
      context,
    }: AddPublicTemplateToOldSeriesArgs) => {
      const { data: response } = await core().publicTemplates.insert.post(
        seriesId,
        {
          public_template_id: templateId,
          insert_at_index: insertAtIndex,
          track_context: context,
        },
      );

      publish(SeriesPrivateEvents.UPDATE_NETWORK_CACHE, {
        cacheKey: questionsCacheKey(seriesId),
      });

      mutate<Array<Question>>(
        questionsCacheKey(seriesId),
        (old) => {
          return update(old, {
            $splice: [[insertAtIndex, 0, ...response.questions]],
          });
        },
        true,
      );

      return null;
    },

    [seriesId, publish, mutate],
  );

  return {
    addPublicTemplateToNewSeries,
    addPublicTemplateToOldSeries,
  };
};
