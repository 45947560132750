import { create } from 'zustand';
import type { NextRouter } from 'next/router';
// eslint-disable-next-line eslint-custom-plugin/no-next-router
import Router from 'next/router';
import { useRouter } from '@mentimeter/deprecated-pages-next-navigate';
import { MentiError, captureException } from '@mentimeter/errors';
import { useEffect, useRef } from 'react';
import { useParams, useSearchParams } from '@mentimeter/next-navigation';

/**
 * This function is very tightly coupled to the URL structure, and only works in mentimote/editor/presview.
 */
export const parseUrlIds = (query: NextRouter['query']) => {
  const { seriesId } = query;
  let { questionId } = query;

  if (Array.isArray(seriesId))
    throw new Error('params are an array, expected string');

  if (!seriesId)
    captureException(
      new MentiError('Could not find seriesId in the URL', {
        feature: 'live',
      }),
    );

  // Do not parse these words as questionIds, as they could be part of legit URLs
  // to respective views without they questionId.
  if (
    questionId === 'edit' ||
    questionId === 'public' ||
    questionId === 'embed' ||
    questionId === 'results'
  )
    questionId = undefined;

  // mentimote
  if (!questionId) return { seriesId: seriesId! };

  if (Array.isArray(questionId))
    throw new Error('params are an array, expected string');

  // everything else
  return { seriesId: seriesId!, questionId };
};

/**
 * @deprecated - Use useActiveSeriesId and/or useActiveQuestionId instead.
 *
 * This function is very tightly coupled to the URL structure, and only works in mentimote/editor/presview.
 * The return value is not memoized. Always destruct the return value, or at least do not use the returned map
 * as in a dependency array.
 **/
export const useIds = () => {
  const seriesId = useIdStore((state) => state.seriesId);
  const questionId = useIdStore((state) => state.questionId);
  return { seriesId, questionId };
};

/**
 * This function is very tightly coupled to the URL structure, and only works in mentimote/editor/presview.
 * Don't use this function inside React components - opt for useIds instead.
 **/
export const getIds = () => {
  return parseUrlIds(Router.query);
};

interface IdStore {
  seriesId: string;
  questionId: string | undefined;
  getQuestionId: () => string | undefined;
  getSeriesId: () => string;
  /**
   * Sets the current `seriesId`. NOTE: Do not use this to try to navigate to a series
   */
  setSeriesId: (seriesId: string) => void;
  /**
   * Sets the current `questionId`. NOTE: Do not use this to try to navigate to a question
   */
  setQuestionId: (questionId: string | undefined) => void;
}

export const useIdStore = create<IdStore>((set, get) => ({
  seriesId: '',
  questionId: undefined,
  getQuestionId: () => get().questionId,
  getSeriesId: () => get().seriesId,
  setSeriesId: (seriesId) => set({ seriesId }),
  setQuestionId: (questionId) => set({ questionId }),
}));

export const useActiveSeriesId = () => useIdStore((state) => state.seriesId);
export const useActiveQuestionId = (): string =>
  useIdStore((state) => state.questionId) as string;

/**
 * This function is very tightly coupled to the URL structure, and only works in mentimote/editor/presview.
 * Use `useIdStore` directly if you need to set the ids by some other means. This function is only meant to be used
 * in the top level of the application, and will update the store when the URL changes.
 **/
export const useNextRouterIds = () => {
  const mounted = useRef(false);
  const { query } = useRouter();
  const { seriesId, questionId } = parseUrlIds(query);
  const setSeriesId = useIdStore((state) => state.setSeriesId);
  const setQuestionId = useIdStore((state) => state.setQuestionId);

  if (!mounted.current) {
    setSeriesId(seriesId);
    setQuestionId(questionId);
  }

  useEffect(() => {
    setSeriesId(seriesId);
    setQuestionId(questionId);
    mounted.current = true;
  }, [questionId, seriesId, setQuestionId, setSeriesId]);

  return { seriesId, questionId };
};

export const useAppRouterIds = (ids?: {
  seriesId?: string | undefined;
  questionId?: string | undefined;
}) => {
  const mounted = useRef(false);
  const params = useParams();
  const search = useSearchParams();
  let seriesId: string | undefined = ids?.seriesId;
  let questionId: string | undefined = ids?.questionId;

  if (params.seriesId && search.get('question')) {
    seriesId = params.seriesId as string;
    questionId = search.get('question') as string;
  }

  const setSeriesId = useIdStore((state) => state.setSeriesId);
  const setQuestionId = useIdStore((state) => state.setQuestionId);

  if (seriesId && !mounted.current) {
    setSeriesId(seriesId);
    setQuestionId(questionId);
  }

  useEffect(() => {
    if (seriesId) {
      setSeriesId(seriesId);
    }
    setQuestionId(questionId);
    mounted.current = true;
  }, [questionId, seriesId, setQuestionId, setSeriesId]);

  return { seriesId, questionId };
};
