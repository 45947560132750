import { Box, List, Text } from '@mentimeter/ragnar-ui';
import { AlertIcon } from '@mentimeter/ragnar-visuals';

export function TemplateWarning() {
  return (
    <>
      <Box flexDirection="row" alignItems="center">
        <AlertIcon />
        <Text ml={2} fontWeight="bold" color="text" lineHeight="none">
          Your changes here affect how templates appear{' '}
          <span
            style={{
              textDecoration: 'underline',
            }}
          >
            live
          </span>{' '}
          to users in the:
        </Text>
      </Box>
      <List ml={2} mt={2}>
        <Text as="li" pl={1} ml={3} color="text">
          <a href="/app/home?templates=true" target="_blank">
            Template Modal
          </a>{' '}
          in the dashboard pages
        </Text>
        <Text as="li" pl={1} ml={3} color="text">
          Template Pane in the Editor
        </Text>
      </List>
    </>
  );
}
