import { Box, IconButton, Text } from '@mentimeter/ragnar-ui';
import React from 'react';
import { Link } from '@mentimeter/next-navigation';
import { CoreHooksProvider, useSeries } from '@mentimeter/core-hooks';
import type { Series } from '@mentimeter/http-clients';
import { useDevelopmentFlag } from '@mentimeter/development-tools/hooks';
import { useUser } from '@mentimeter/user';
import { TemplateEditorDataHandler } from '@mentimeter/template-editor/modal';
import { PencilIcon } from '@mentimeter/ragnar-visuals';

export function TemplateModalHiddenInfo({
  seriesId,
  activeTemplateId = 0,
  selectedCategoryId = 0,
}: {
  seriesId: string | undefined;
  activeTemplateId: number | undefined;
  selectedCategoryId: number | undefined;
}) {
  const { user } = useUser();
  const userIsTemplateEditor = user?.features.template_editor;
  const templateHelpersEnabled = useDevelopmentFlag('template-helpers');
  if (!userIsTemplateEditor && templateHelpersEnabled !== 'on') return null;

  return (
    <Box
      position="absolute"
      right={0}
      top={0}
      flexDirection="row"
      pt="space6"
      pr="space12"
      alignItems="center"
      justifyContent="flex-end"
      gap="space4"
    >
      {seriesId && userIsTemplateEditor && (
        <TemplateEditorHelpers seriesId={seriesId} />
      )}
      <TemplateInfo
        activeTemplateId={activeTemplateId}
        selectedCategoryId={selectedCategoryId}
      />
    </Box>
  );
}

function TemplateInfo({
  activeTemplateId,
  selectedCategoryId,
}: {
  activeTemplateId: number | undefined;
  selectedCategoryId: number | undefined;
}) {
  return (
    <>
      <Text>
        {!!activeTemplateId && 'Active template ID: ' + activeTemplateId}
      </Text>
      <Text>
        {!!selectedCategoryId && 'Active category ID: ' + selectedCategoryId}
      </Text>
    </>
  );
}

function TemplateEditorHelpers({ seriesId }: { seriesId: string }) {
  return (
    <CoreHooksProvider
      device_id="templates-debug"
      seriesId={seriesId}
      shouldSubscribe={false}
    >
      <TemplateEditorDataHandler seriesId={seriesId}>
        <IconButton
          size="compact"
          ml="space1.5"
          variant="secondary"
          id="edit-template"
          title="Edit presentation template"
        >
          <PencilIcon color="inherit" />
        </IconButton>
      </TemplateEditorDataHandler>
      <SeriesHelpersInner seriesId={seriesId} />
    </CoreHooksProvider>
  );
}

function SeriesHelpersInner({ seriesId }: { seriesId: string }) {
  const { data: series } = useSeries(seriesId);

  if (!series) return null;

  return (
    <Link target="_blank" href={`${getLinkToLocation(series)}`}>
      Open at location
    </Link>
  );
}

function getLinkToLocation(series: Series) {
  if (series.workspace_id) {
    if (series.folder_id) return `/app/workspace/folder/${series.folder_id}`;
    else return '/app/workspace';
  } else {
    if (series.folder_id) return `/app/folder/${series.folder_id}`;
    return `/app/dashboard`;
  }
}
