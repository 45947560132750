import { core, type DuplicateSeriesPayload } from '@mentimeter/http-clients';
import type { UseUserT } from '@mentimeter/user';

const DEFAULT_REGION = 'eu';

export const duplicateSeries = async (
  { isAuthenticated, user }: Pick<UseUserT, 'isAuthenticated' | 'user'>,
  payload: DuplicateSeriesPayload,
) => {
  if (!isAuthenticated || !user) {
    window.location.assign('/auth/login');
    return Promise.reject(new Error('Unauthorized'));
  }

  const coreClient = core({ region: user.region || DEFAULT_REGION });
  const { data } = await coreClient.duplicate.series(payload);

  return {
    seriesId: data.id,
    // @ts-expect-error-auto TS(2532) FIXME: Object is possibly 'undefined'.
    questionId: data.questions[0].id,
  };
};
