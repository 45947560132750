import { Box, Text, Textarea } from '@mentimeter/ragnar-ui';
import { QuestionMarkCircleIcon } from '@mentimeter/ragnar-visuals';
import { DescriptionPreview } from '@mentimeter/templates-modal/description-preview';
import { MarkdownInstructionsPopover } from './MarkdownInstructionsPopover';

export const DESCRIPTION_EDITOR_HEIGHT = '600px';

export function DescriptionEditor({
  description,
  onChange,
}: {
  description: string;
  onChange: (newDescription: string) => void;
}) {
  return (
    <>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        mb="space0.5"
      >
        <MarkdownInstructionsPopover>
          <Box
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            extend={() => ({
              ':hover': {
                cursor: 'pointer',
              },
            })}
          >
            <Text
              fontSize="87.5"
              fontWeight="semiBold"
              mr="space1"
              color="inherit"
            >
              Markdown
            </Text>
            <QuestionMarkCircleIcon />
          </Box>
        </MarkdownInstructionsPopover>

        <Text fontSize="87.5" fontWeight="semiBold">
          Preview
        </Text>
      </Box>

      <Box
        flexDirection="row"
        maxWidth="100%"
        height={DESCRIPTION_EDITOR_HEIGHT}
      >
        <Box flex="0 1 50%" pr="space4">
          <Textarea
            id="recommended-template-description-input"
            name="description"
            aria-label="Description"
            autoComplete="off"
            displayMarkdownMenu
            minHeight={DESCRIPTION_EDITOR_HEIGHT}
            maxHeight={DESCRIPTION_EDITOR_HEIGHT}
            onChange={(e) => {
              e.preventDefault();
              onChange(e.target.value);
            }}
            value={description}
          />
        </Box>

        <Box
          flex="0 1 50%"
          height="100%"
          p="space3"
          borderWidth={1}
          borderStyle="solid"
          borderColor="neutral"
          borderRadius="lg"
          overflow="auto"
        >
          <DescriptionPreview description={description} />
        </Box>
      </Box>
    </>
  );
}
