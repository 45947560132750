import type {
  PostPublicTemplate,
  PublicTemplatesCategoryT,
  PublicTemplateT,
} from '@mentimeter/http-clients';
import { type ReactNode, useState } from 'react';
import type { FormStateT, ThumbnailT } from '../types';
import { TemplateEditorContent } from './TemplateEditorContent';

export function TemplateEditor({
  open,
  onOpenChange,
  templateSeriesId,
  templateName,
  templateLink,
  existingTemplate,
  thumbnails,
  categories,
  onSave,
  children,
}: {
  open: boolean;
  onOpenChange: (isOpen: boolean) => void;
  templateSeriesId: string;
  templateName: string;
  templateLink: string;
  thumbnails: ThumbnailT[];
  existingTemplate: PublicTemplateT | undefined;
  categories: PublicTemplatesCategoryT[];
  onSave(template: PostPublicTemplate): Promise<PublicTemplateT>;
  children: ReactNode;
}) {
  const defaultThumbnailId = thumbnails[0]?.id ?? null;
  if (!defaultThumbnailId) throw new Error('No questions exist');
  const defaultCategoryId = categories[0]?.id ?? null;
  if (!defaultCategoryId) throw new Error('No categories exist');

  const hasUnsavedChangesFromTheStart =
    existingTemplate && existingTemplate.category_id === null;
  const [formState, setFormState] = useState<FormStateT>(
    existingTemplate
      ? existingTemplateFormState(existingTemplate, defaultCategoryId)
      : newTemplateFormState(defaultThumbnailId, defaultCategoryId),
  );
  const [saveState, setSaveState] = useState<'saved' | 'saving' | 'unsaved'>(
    existingTemplate && !hasUnsavedChangesFromTheStart ? 'saved' : 'unsaved',
  );
  const isPublished = existingTemplate?.published ?? false;

  return (
    <TemplateEditorContent
      open={open}
      onOpenChange={onOpenChange}
      templateName={templateName}
      templateLink={templateLink}
      categories={categories}
      formState={formState}
      onChange={onChange}
      onSaveChanges={save}
      onTogglePublish={togglePublished}
      saveState={saveState}
      isPublished={isPublished}
      thumbnails={thumbnails}
    >
      {children}
    </TemplateEditorContent>
  );

  function onChange(newFormState: FormStateT) {
    setFormState(newFormState);
    setSaveState('unsaved');
  }

  async function togglePublished() {
    if (!existingTemplate) {
      await save({ published: !isPublished });
    } else {
      setSaveState('saving');
      await onSave({
        preview_question_admin_key:
          existingTemplate.preview_question_admin_key ?? defaultThumbnailId,
        category_id: existingTemplate.category_id ?? defaultCategoryId,
        series_id: templateSeriesId,
        description: existingTemplate.description,
        published: !isPublished,
      });
      setSaveState('saved');
    }
  }

  async function save(
    { published }: { published: boolean } = { published: isPublished },
  ) {
    setSaveState('saving');
    const newTemplateData = await onSave({
      preview_question_admin_key: formState.questionId,
      category_id: formState.categoryId,
      series_id: templateSeriesId,
      description: formState.description,
      published,
    });
    setSaveState('saved');

    setFormState({
      ...formState,
      templateId: newTemplateData.id,
      alreadyExists: true,
    });
  }
}

function existingTemplateFormState(
  existingTemplate: PublicTemplateT,
  defaultCategoryId: number,
) {
  return {
    categoryId: existingTemplate.category_id ?? defaultCategoryId,
    questionId: existingTemplate.preview_question_admin_key,
    templateId: existingTemplate.id,
    description: existingTemplate.description,
    alreadyExists: true,
  };
}

function newTemplateFormState(
  defaultThumbnailId: string,
  defaultCategoryId: number,
) {
  return {
    categoryId: defaultCategoryId,
    questionId: defaultThumbnailId,
    templateId: -1,
    description: '',
    alreadyExists: false,
  };
}
