import type { UserPresentationRole } from '@mentimeter/http-clients';

export type SetSelectedSlideIds = (slides: Array<string>) => Array<string>;

export interface PresentationAuthState {
  isInitialized: boolean;
  isAuthorized: boolean;
  userPresentationRole: UserPresentationRole;
}

export enum EditorFlags {
  HasPresented = 'hasPresented',
  NewPresentationMode = 'newPresentationMode',
  QuestionAddMode = 'questionAddMode',
  OperationLock = 'operationLock',
  IsUpdating = 'isUpdating',
}

export type EditorErrors =
  | 'createRatingGridFromFile'
  | 'updateCroppedRatingGrid'
  | 'updateQuestionRequest'
  | 'archiveActiveQuestionResults'
  | 'removeQuestions'
  | 'copyQuestion'
  | 'copyCoreQuestions'
  | 'addQuestions'
  | 'updateQuestionType'
  | 'updateSeries'
  | 'addExample'
  | 'updateSegmentations'
  | 'updateQuestionsVisibility';

export interface CoreGlobalState<ErrorT = EditorErrors> {
  selectedSlideIds: Array<string>;
  setSelectedSlideIds: (ids: Array<string> | SetSelectedSlideIds) => void;

  editorFlags: Record<EditorFlags, boolean>;
  setEditorFlag: (flagKey: EditorFlags, value: boolean) => void;

  persisting: Array<string>;
  startPersisting: (cacheKey: string) => void;
  stopPersisting: (cacheKey: string) => void;
  resetPersisting: () => void;

  requestDone: () => void;
  lastActiveRequest: number;

  error: ErrorT | undefined;
  setError: (error: ErrorT | undefined) => void;

  presentationAuth: PresentationAuthState;
  setPresentationAuth: (
    values: Omit<PresentationAuthState, 'isInitialized'>,
  ) => void;
}
