import type { DuplicateSeriesPayload } from '@mentimeter/http-clients';
import type { ButtonT } from '@mentimeter/ragnar-ui';
import { useUser } from '@mentimeter/user';
import { useState, useCallback } from 'react';
import { duplicateSeries } from './duplicate-series';

export const TEMPLATES_REGION = 'eu';

const ERROR_RESET_DELAY = 2000;

interface Props {
  context: DuplicateSeriesPayload['track_context'];
  region: DuplicateSeriesPayload['region'];
  tags?: DuplicateSeriesPayload['tags'];
  successCallback?: (params: { seriesId: string; questionId: string }) => void;
  errorCallback?: () => void;
}

export const useCloneTemplate = ({
  context,
  tags,
  region,
  successCallback = () => {},
  errorCallback = () => {},
}: Props) => {
  const [state, setState] = useState<ButtonT['state']>();
  const { isAuthenticated, user } = useUser();

  const cloneTemplate = useCallback(
    async (copySeriesId: string) => {
      setState('loading');
      try {
        const { seriesId, questionId } = await duplicateSeries(
          { isAuthenticated, user },
          {
            copy: copySeriesId,
            track_context: context,
            tags: tags ?? ['Example'],
            region,
          },
        );
        setState('success');

        successCallback?.({
          seriesId,
          questionId,
        });
      } catch {
        setState('error');
        setTimeout(() => {
          setState(undefined);
        }, ERROR_RESET_DELAY);
        errorCallback?.();
      }
    },
    [
      isAuthenticated,
      user,
      context,
      tags,
      region,
      successCallback,
      errorCallback,
    ],
  );

  return {
    cloneTemplate,
    state,
  };
};
