import React from 'react';
import { Box, Markdown } from '@mentimeter/ragnar-ui';

export function DescriptionPreview({ description }: { description: string }) {
  return (
    <Box>
      <Markdown
        allowedTypes={[
          'text',
          'paragraph',
          'emphasis',
          'strong',
          'list',
          'listItem',

          // WARNING 2024-04-24:
          // If we remove thematicBreak as an allowed element then every list item
          // is rendered as a separate list, which is not desirable.
          'thematicBreak',

          'heading',
          'link',
        ]}
      >
        {description}
      </Markdown>
    </Box>
  );
}
