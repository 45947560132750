import React from 'react';
import { Text } from '../Text';
import { Box } from '../Box';
import {
  MarkdownEmphasis,
  MarkdownHeading,
  MarkdownParagraph,
  MarkdownStrong,
} from './Markdown';

export function MarkdownInstructions() {
  return (
    <>
      <MarkdownHeading level={1}># Heading 1</MarkdownHeading>
      <MarkdownHeading level={2}>## Heading 2</MarkdownHeading>
      <MarkdownHeading level={3}>### Heading 3</MarkdownHeading>
      <br />
      <MarkdownEmphasis>*Italic text* _italic text_</MarkdownEmphasis>
      <br />
      <MarkdownStrong>**Bold text** __bold text__</MarkdownStrong>
      <br />
      <Text>
        <Box>- List item</Box>
        <Box>- Another list item</Box>
      </Text>
      <br />

      <Text>
        <Box>1. Numbered list item</Box>
        <Box>1. Second numbered list item...</Box>
        <Box>1. Third item (numbers will be set automatically from 1-x)</Box>
      </Text>
      <br />
      <Box
        width="320px"
        p="space2"
        borderWidth={1}
        borderStyle="solid"
        borderColor="neutral"
        borderRadius="lg"
      >
        <MarkdownParagraph>
          Write text without a new line to keep it all in the same paragraph.
          Text automatically wraps when it reaches the end of the line.
        </MarkdownParagraph>
        <MarkdownParagraph>
          This line for example will show up as a new paragraph!
        </MarkdownParagraph>
      </Box>
    </>
  );
}
