import { Box, Text } from '@mentimeter/ragnar-ui';
import { LazySlidePreview } from '@mentimeter/slide-preview-loader';

export const SlidePreview = ({
  presentationId,
  questionId,
  questionTitle,
  slideNumber,
  ...rest
}: {
  presentationId: string;
  questionId: string;
  slideNumber: number;
  questionTitle: string;
}) => {
  return (
    <Box
      borderColor="borderWeak"
      borderStyle="solid"
      borderWidth={1}
      borderRadius="2px"
      width="100%"
      bg="neutralWeakest"
      {...rest}
    >
      {questionId && (
        <>
          <LazySlidePreview
            seriesId={presentationId}
            displayQuestionId={questionId}
            exampleResultsMode="on-hover-always"
            enableTestVotesIndicator
          />
          <Box width="100%">
            <Box
              position="absolute"
              bottom="0"
              height="32px"
              width="32px"
              bg="#00000080"
              justifyContent="center"
              alignItems="center"
              borderRadius="2px"
            >
              <Text fontWeight="semiBold" color="white">
                {slideNumber}
              </Text>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
