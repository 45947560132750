import { SelectItem } from '@mentimeter/ragnar-ui';
import type { ThumbnailT } from '../types';

export function ThumbnailSelect({
  selectedThumbnailId,
  thumbnails,
  onChange,
}: {
  selectedThumbnailId: string;
  thumbnails: ThumbnailT[];
  onChange: (selectedThumbnail: ThumbnailT) => void;
}) {
  return (
    <SelectItem
      id="recommended-template-thumbnail-dropdown"
      name="template-thumbnail-dropdown"
      aria-label="Thumbnail"
      onChange={(e) => {
        e.preventDefault();

        const option = thumbnails.find((t) => t.id === e.target.value);
        if (!option) return;

        onChange(option);
      }}
      value={selectedThumbnailId}
    >
      {thumbnails.map(({ title, id }, index) => (
        <SelectItem.Option key={id} value={id}>
          {`${index + 1}. ${title}`}
        </SelectItem.Option>
      ))}
    </SelectItem>
  );
}
