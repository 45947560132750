import {
  type PublicTemplateT,
  type PublicTemplatesCategoryT,
} from '@mentimeter/http-clients';
import { useMatch } from '@mentimeter/ragnar-device';
import {
  Box,
  CheckItem,
  Label,
  Clickable,
  ModalBody,
  ModalContainer,
  ModalHeader,
  Skeleton,
  Text,
} from '@mentimeter/ragnar-ui';
import { addUnit } from '@mentimeter/ragnar-utils';
import React, { useEffect, type JSX } from 'react';
import { trackUser } from '@api/tracking/client';
import { TemplateCard } from '../TemplateCard';
import { TemplateDetails } from '../TemplateDetails/TemplateDetails';
import {
  ALL_TEMPLATES_CATEGORY,
  getSupportedMainUsage,
  useApiTemplateByCategory,
  useApiTemplateCategories,
  useUserProfile,
  type PublicTemplateUseCase,
} from '../hooks/usePublicTemplates';
import type { ModalOpeningContextT, ModalTrackingContextT } from '../types';
import { useHasSkippedTemplateModalStore } from '../useHasSkippedTemplateModalStore';
import { StartFromScratch } from './StartFromScratch';
import { TemplateModalHiddenInfo } from './TemplateModalHiddenInfo';

const CategoryLink = ({
  children,
  onClick,
  isActive,
  hideIndicator = false,
}: {
  children: React.ReactNode;
  isActive: boolean;
  hideIndicator?: boolean;
  onClick: () => void;
}) => {
  return (
    <Clickable
      onClick={() => onClick()}
      width="100%"
      borderRadius="lg"
      extend={({ theme }) => ({
        '@media (hover: hover)': {
          '&:hover': {
            opacity: 1,
            backgroundColor: theme.colors.secondaryWeakest,
          },
        },
        '&:focus-visible': {
          backgroundColor: theme.colors.secondaryWeakest,
        },
      })}
    >
      <Box py="space2" px="space3" flexDirection="row" width="100%">
        <Text
          truncate
          fontWeight={isActive ? 'semiBold' : 'regular'}
          color={isActive ? 'primary' : 'textWeak'}
          position="relative"
          overflow="visible"
          fontSize="87.5"
          extend={({ theme }) => ({
            '&:before': {
              content: hideIndicator ? '' : '""',
              display: isActive ? 'inline-block' : 'none',
              position: 'absolute',
              left: `-${theme.kosmosSpacing.space3}px`,
              height: '100%',
              width: '2px',
              backgroundColor: theme.colors.secondary,
              borderRadius: addUnit(theme.kosmosBorderRadius.full),
            },
          })}
        >
          {children}
        </Text>
      </Box>
    </Clickable>
  );
};

interface TemplatesOverviewProps {
  selectedCategory: PublicTemplatesCategoryT;
  setSelectedCategory: (category: PublicTemplatesCategoryT) => void;
  setActiveTemplate: (template: PublicTemplateT) => void;
  isNewPresentation?: boolean | undefined;
  isUsersFirstSeries: boolean;
  createPresentation?:
    | (({ serieName }: { serieName: string }) => Promise<void>)
    | undefined;
  modalOpeningContext: ModalOpeningContextT;
  onStartFromScratchInEditor?: (() => void) | undefined;
  closeModal: () => void;
  trackModal: (action: 'hide' | 'closed' | 'opened') => void;
  activeQuestionIndex: number | undefined;
  isNewPresentationMode: boolean;
}

const TemplatesOverview = ({
  selectedCategory,
  setSelectedCategory,
  setActiveTemplate,
  isNewPresentation,
  isUsersFirstSeries,
  createPresentation,
  modalOpeningContext,
  onStartFromScratchInEditor,
  closeModal,
  trackModal,
  activeQuestionIndex,
  isNewPresentationMode,
}: TemplatesOverviewProps) => {
  const { data: userProfile } = useUserProfile();
  const primaryMainUsage = getSupportedMainUsage(userProfile);

  const { data: templates } = useApiTemplateByCategory(
    selectedCategory.main_usage ?? primaryMainUsage,
    selectedCategory.id,
  );

  const [showMoreCategories, setShowMoreCategories] = React.useState(false);

  const toggleShowMoreCategories = () => {
    setShowMoreCategories((prev) => !prev);
  };

  const hasSkippedTemplateModal = useHasSkippedTemplateModalStore(
    (state) => state.hasSkippedTemplateModal,
  );
  const setHasSkippedTemplateModal = useHasSkippedTemplateModalStore(
    (state) => state.setHasSkippedTemplateModal,
  );
  const toggleSkipTemplateModal = () => {
    if (!hasSkippedTemplateModal) {
      setHasSkippedTemplateModal(true);
      trackModal('hide');
    } else {
      setHasSkippedTemplateModal(false);
    }
  };

  const secondaryUsage =
    primaryMainUsage === 'business' ? 'education' : 'business';

  const renderStartFromScratch = isNewPresentation && templates;
  const isMobileOrTablet = useMatch({ lessThan: 4 });
  const isMobile = useMatch({ lessThan: 2 });

  return (
    <>
      <Box
        width={['100%', '100%', '200px']}
        height={['auto', 'auto', '100%']}
        alignItems="center"
        overflow="auto"
        p="space2"
        ml="-space2"
        mt="-space2"
        mr="space10"
        mb={isMobileOrTablet ? 'space10' : 'space0'}
      >
        <Box width="100%" gap="space1">
          <CategoryLink
            isActive={selectedCategory.id === ALL_TEMPLATES_CATEGORY.id}
            onClick={() => setSelectedCategory(ALL_TEMPLATES_CATEGORY)}
          >
            {ALL_TEMPLATES_CATEGORY.name}
          </CategoryLink>
          <TemplateCategory skipFetch={false} mainUsage={primaryMainUsage}>
            {(category) => {
              return (
                <CategoryLink
                  isActive={selectedCategory.id === category.id}
                  onClick={() => setSelectedCategory(category)}
                >
                  {category.name}
                </CategoryLink>
              );
            }}
          </TemplateCategory>

          <CategoryLink
            isActive={showMoreCategories}
            onClick={toggleShowMoreCategories}
            hideIndicator
          >
            See more categories
          </CategoryLink>
        </Box>
        {showMoreCategories && (
          <Box width="100%" gap="space1">
            <TemplateCategory
              skipFetch={!showMoreCategories}
              mainUsage={secondaryUsage}
            >
              {(category) => {
                return (
                  <CategoryLink
                    isActive={selectedCategory.id === category.id}
                    onClick={() => setSelectedCategory(category)}
                  >
                    {category.name}
                  </CategoryLink>
                );
              }}
            </TemplateCategory>
          </Box>
        )}
        <Box
          width="100%"
          mt="auto"
          pt="space3"
          flexDirection="row"
          gap="space3"
          alignItems="center"
        >
          <CheckItem
            type="checkbox"
            id="toggle-skip-modal"
            name="toggle-skip-modal"
            size={isMobile ? 'compact' : 'default'}
            onChange={toggleSkipTemplateModal}
            checked={hasSkippedTemplateModal}
          />
          <Label
            htmlFor="toggle-skip-modal"
            mb="space0"
            fontWeight="regular"
            fontSize="87.5"
            lineHeight="normal"
          >
            Skip templates when creating
          </Label>
        </Box>
      </Box>

      <Box
        overflow={['unset', 'unset', 'auto']}
        width="100%"
        extend={() => ({
          flexShrink: isMobileOrTablet ? 'unset' : 1,
        })}
      >
        <Box width="100%" mb={[1, 1, 3]} mt={[3, 3, 0]}>
          <Text
            as="h3"
            fontFamily="heading"
            fontWeight="semiBold"
            fontSize="100"
            lineHeight="150"
            color="text"
          >
            {selectedCategory.name}
          </Text>
          {selectedCategory.description && (
            <Text
              as="p"
              fontFamily="body"
              fontSize="100"
              lineHeight="150"
              color="text"
            >
              {selectedCategory.description}
            </Text>
          )}
        </Box>
        <Box
          width="100%"
          data-testid="templates-overview"
          mt={3}
          display="grid"
          gap="space4"
          extend={() => {
            const numberOfColumns = isMobile ? 1 : 2;
            return {
              gridTemplateColumns: `repeat(${numberOfColumns}, 1fr)`,
            };
          }}
        >
          {renderStartFromScratch && (
            <StartFromScratch
              isFirst={isUsersFirstSeries}
              // @ts-expect-error-auto TS(2322) FIXME: Type '(({ serieName }: { serieName: string; }) => ... Remove this comment to see the full error message
              createPresentation={createPresentation}
              modalOpeningContext={modalOpeningContext}
              closeModal={closeModal}
              onStartFromScratchInEditor={onStartFromScratchInEditor}
            />
          )}
          {templates?.map((template) => {
            return (
              <TemplateCard
                key={template.series_id}
                onPreview={setActiveTemplate}
                onClick={() => setActiveTemplate(template)}
                trackingContext="Dashboard modal"
                modalOpeningContext={modalOpeningContext}
                isNewPresentationMode={isNewPresentationMode}
                closeModal={closeModal}
                insertAtIndex={(activeQuestionIndex || 0) + 1}
                template={template}
              />
            );
          })}
        </Box>
      </Box>
    </>
  );
};
const TemplateCategory = ({
  children,
  mainUsage,
  skipFetch,
}: {
  mainUsage: PublicTemplateUseCase;
  skipFetch: boolean;
  children: (category: PublicTemplatesCategoryT) => JSX.Element;
}) => {
  const { data } = useApiTemplateCategories(skipFetch, mainUsage);

  return data ? (
    <>
      {data.map((c) => (
        <React.Fragment key={c.id}>{children(c)}</React.Fragment>
      ))}
    </>
  ) : (
    <>
      <Skeleton mb={2} width={0.8} />
      <Skeleton mb={2} width={0.8} />
      <Skeleton mb={2} width={0.8} />
    </>
  );
};

interface TemplateModalProps {
  selectedTemplate?: PublicTemplateT | undefined;
  selectedCategory?: PublicTemplatesCategoryT | undefined;
  onDismiss?: () => void;
  createPresentation?: ({ serieName }: { serieName: string }) => Promise<void>;
  isNewPresentation?: boolean;
  isUsersFirstSeries?: boolean;
  trackingContext: ModalTrackingContextT;
  modalOpeningContext: ModalOpeningContextT;
  open: boolean;
  isNewPresentationMode?: boolean;
  activeQuestionIndex?: number;
  onStartFromScratchInEditor?: () => void;
}

export const TemplateModal = ({
  selectedTemplate,
  selectedCategory: preselectedCategory,
  onDismiss,
  createPresentation,
  isNewPresentation = false,
  isUsersFirstSeries = false,
  trackingContext,
  modalOpeningContext,
  open,
  isNewPresentationMode = false,
  activeQuestionIndex,
  onStartFromScratchInEditor,
}: TemplateModalProps) => {
  const [selectedCategory, setSelectedCategory] =
    React.useState<PublicTemplatesCategoryT>(
      preselectedCategory ?? ALL_TEMPLATES_CATEGORY,
    );

  const [activeTemplate, setActiveTemplate] = React.useState<
    PublicTemplateT | undefined
  >(selectedTemplate);

  const trackModal = React.useCallback(
    (action: 'closed' | 'opened' | 'hide') => {
      if (open) {
        const trackingData = () => {
          switch (action) {
            case 'opened':
              return {
                event: 'Opened template modal',
                properties: {
                  context: trackingContext,
                },
              };
            case 'closed':
              return { event: 'Closed template modal' };
            default:
              return { event: 'Hide template modal for new presentations' };
          }
        };
        trackUser(trackingData());
      }
    },
    [open, trackingContext],
  );

  const closeModal = () => {
    trackModal('closed');
    if (onDismiss) onDismiss();
  };

  useEffect(() => {
    trackModal('opened');
  }, [trackModal]);

  return (
    <ModalContainer
      height="80vh"
      size="large"
      onEscapeKeyDown={(e) => {
        e.stopPropagation();
        closeModal();
      }}
      data-testid="templates-modal"
    >
      <ModalHeader
        size="medium"
        dismissLabel="Close Templates dialog"
        onDismissClick={closeModal}
      >
        Templates
      </ModalHeader>
      <TemplateModalHiddenInfo
        seriesId={activeTemplate?.series_id}
        activeTemplateId={activeTemplate?.id}
        selectedCategoryId={selectedCategory?.id}
      />
      <ModalBody>
        <Box
          width="100%"
          height="100%"
          flexDirection={['column', 'column', 'row']}
          alignItems="stretch"
        >
          {activeTemplate ? (
            <TemplateDetails
              template={activeTemplate}
              onBack={() => setActiveTemplate(undefined)}
              trackingContext={trackingContext}
              modalOpeningContext={modalOpeningContext}
              closeModal={closeModal}
              isNewPresentationMode={isNewPresentationMode}
              insertAtIndex={(activeQuestionIndex || 0) + 1}
            />
          ) : (
            <TemplatesOverview
              isNewPresentation={isNewPresentation}
              isUsersFirstSeries={isUsersFirstSeries}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              setActiveTemplate={setActiveTemplate}
              createPresentation={createPresentation}
              modalOpeningContext={modalOpeningContext}
              closeModal={closeModal}
              trackModal={trackModal}
              activeQuestionIndex={activeQuestionIndex || 0}
              isNewPresentationMode={isNewPresentationMode}
              onStartFromScratchInEditor={onStartFromScratchInEditor}
            />
          )}
        </Box>
      </ModalBody>
    </ModalContainer>
  );
};
