import type { PublicTemplateT, Series } from '@mentimeter/http-clients';
import { useMatch } from '@mentimeter/ragnar-device';
import { Box, Button, Text, Heading } from '@mentimeter/ragnar-ui';
import type { RagnarButtonState } from '@mentimeter/ragnar-ui';
import { ArrowLeftIcon } from '@mentimeter/ragnar-visuals';
import React from 'react';
import { trackUser } from '@api/tracking/client';
import { getQuestionTypesMappedByName } from '../TemplateModal/utils';
import { useAddPublicTemplate } from '../hooks/useAddPublicTemplate';
import type { ModalOpeningContextT, ModalTrackingContextT } from '../types';
import { DescriptionPreview } from '../src/description-preview';
import { AddTemplateButton } from './AddTemplateButton';
import { DetailsSkeleton } from './DetailsSkeleton';
import { SlidePreview } from './SlidePreview';
import { getTemplatePresentation } from './use-get-template-presentation';

export const TemplateDetails = ({
  template,
  onBack,
  closeModal,
  trackingContext,
  modalOpeningContext,
  isNewPresentationMode,
  insertAtIndex,
}: {
  template: PublicTemplateT;
  onBack: () => void;
  closeModal: () => void;
  trackingContext: ModalTrackingContextT;
  modalOpeningContext: ModalOpeningContextT;
  isNewPresentationMode: boolean;
  insertAtIndex: number;
}) => {
  const [presentation, setPresentation] = React.useState<Series | null>(null);
  const [questionTypesMappedByName, setQuestionTypesMappedByName] =
    React.useState<string[]>([]);
  const [buttonState, setButtonState] = React.useState<RagnarButtonState>();

  const isMobileOrTablet = useMatch({ lessThan: 4 });
  const isMobile = useMatch({ lessThan: 2 });

  const onCloseModal = (): void => {
    closeModal();
    setButtonState(undefined);
  };

  const { handleClickClone, state: addTemplateButtonState } =
    useAddPublicTemplate({
      template,
      isNewPresentationMode,
      insertAtIndex,
      modalOpeningContext,
      trackingContext,
      closeModal: onCloseModal,
    });

  React.useEffect(() => {
    setButtonState(addTemplateButtonState);
  }, [addTemplateButtonState]);

  React.useEffect(() => {
    getTemplatePresentation(template.series_id).then(({ data }) => {
      setPresentation(data);
      setQuestionTypesMappedByName(getQuestionTypesMappedByName(data));
    });
  }, [template]);

  const questions = presentation
    ? presentation.questions
    : Array(template.slide_count).fill(1);

  React.useEffect(() => {
    trackUser({
      event: 'Opened template preview',
      properties: {
        context: trackingContext,
        template: template.name,
        'series id': template.series_id,
      },
    });
  }, [template, trackingContext]);

  return (
    <Box width="100%">
      <Button
        size="large"
        variant="subtle"
        onClick={onBack}
        iconLeading={ArrowLeftIcon}
      >
        Back
      </Button>
      <Box
        width="100%"
        height="100%"
        color="text"
        flexDirection={['column', 'column', 'row']}
        alignItems="stretch"
        pt={4}
      >
        <Box width={['100%', '100%', '348px']} mr={5}>
          {!presentation ? (
            <DetailsSkeleton />
          ) : (
            <>
              <Heading mb={2}>{template.name}</Heading>
              {template.description && (
                <DescriptionPreview description={template.description} />
              )}
              <Text
                color="text"
                fontSize={1}
                mb={2}
                mt="space4"
                fontWeight="semiBold"
              >
                Interactions included
              </Text>
              <Box flexDirection="row" flexWrap="wrap">
                {questionTypesMappedByName?.map((name) => {
                  return (
                    <Box
                      key={name}
                      mr={2}
                      px={2}
                      py={1}
                      mb={2}
                      borderColor="borderWeak"
                      borderStyle="solid"
                      borderWidth={2}
                      borderRadius={3}
                    >
                      <Text fontSize={1}>{name}</Text>
                    </Box>
                  );
                })}
              </Box>
              <Box mt={4}>
                <AddTemplateButton
                  handleClickClone={handleClickClone}
                  addTemplateButtonState={buttonState}
                />
              </Box>
            </>
          )}
        </Box>

        <Box
          overflow="auto"
          width="100%"
          extend={() => ({
            scrollbarGutter: 'stable', // Only solves scroll-bar flickering for modern browsers other than Safari
            flexShrink: isMobileOrTablet ? 'unset' : 1,
          })}
        >
          <Box
            display="grid"
            width="100%"
            extend={() => ({
              gridGap: '24px',
              gridTemplateColumns: isMobile
                ? 'repeat(1, 1fr)'
                : 'repeat(2, 1fr)',
            })}
          >
            {questions.map((question, index) => {
              return (
                <SlidePreview
                  key={question?.id || index}
                  questionId={question?.id}
                  questionTitle={question?.question}
                  // @ts-expect-error-auto TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  presentationId={presentation?.id}
                  slideNumber={index + 1}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
