import useSWR from 'swr';
import { core } from '@mentimeter/http-clients';

export function useAdminTemplate(seriesId: string) {
  return useSWR(
    ['template-editor', seriesId],
    async () => {
      const {
        data: { data: templates },
      } = await core().templateEditor.publicTemplates.get(seriesId);

      return templates[0];
    },
    { revalidateOnFocus: false, keepPreviousData: true },
  );
}
