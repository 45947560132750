import type { Question } from '@mentimeter/http-clients';
import { core } from '@mentimeter/http-clients';
import React, { useCallback } from 'react';
import type { SWRConfiguration } from 'swr';
import { useSWRConfig } from 'swr';
import { getRegionBySeriesId } from '@mentimeter/region';
import { questionsCacheKey } from './cache-keys';
import type { UseQuestions, WrappedMutator } from './types';
import { useData } from './use-data';

export const useQuestions: UseQuestions = (
  seriesId,
  config?: SWRConfiguration<Array<Question>>,
) => {
  const cacheKey = questionsCacheKey(seriesId);

  const fetcher = useCallback(async () => {
    const { data } = await core({
      region: getRegionBySeriesId(seriesId),
    }).series.get(seriesId);
    return data.questions;
  }, [seriesId]);

  const { data, error, revalidate, lazyData } = useData(
    {
      cacheKey,
      seriesId,
      fetcher,
    },
    config,
  );

  return React.useMemo<ReturnType<UseQuestions>>(
    () => ({
      data: data ?? [],
      error,
      revalidate,
      lazyData,
    }),
    [data, error, revalidate, lazyData],
  );
};

export const useQuestionsMutate = (seriesId: string) => {
  const { mutate } = useSWRConfig();
  return React.useCallback<WrappedMutator<Question[]>>(
    (data, options) => {
      mutate<Question[]>(questionsCacheKey(seriesId), data, {
        revalidate: false,
        ...options,
      });
    },
    [seriesId, mutate],
  );
};
