import useSWRMutation from 'swr/mutation';
import { core, type PostPublicTemplate } from '@mentimeter/http-clients';

export function useUpsertAdminTemplate(
  seriesId: string,
  templateId: number | null,
) {
  return useSWRMutation(
    ['template-editor', seriesId],
    async (key: string[], { arg: data }: { arg: PostPublicTemplate }) => {
      const newData = templateId
        ? await core().templateEditor.publicTemplates.put(templateId, data)
        : await core().templateEditor.publicTemplates.post(data);

      return newData.data;
    },
  );
}
