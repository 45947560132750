import * as React from 'react';
import type { BoxT } from '@mentimeter/ragnar-ui';
import { ChevronDownIcon } from '@mentimeter/ragnar-visuals';
import { useMatch } from '@mentimeter/ragnar-device';
import { Skeleton } from '../Skeleton';
import { Button } from '../Button';
import { Box } from '../Box';
import { ClickableIcon } from '../ClickableIcon';
import { TooltipDescriptive } from '../Tooltip';
import { Text } from '../Text';

interface ToggleChevronPropsT {
  id: string;
  show: boolean;
  onClick?: React.MouseEventHandler;
}

const ToggleChevron = ({ id, show, onClick }: ToggleChevronPropsT) => (
  <Box
    id={id}
    extend={({ theme }) => ({
      transition: `transform ${theme.durations[1]}s ease`,
      transform: `rotate(${show ? 180 : 0}deg)`,
    })}
  >
    <ClickableIcon onClick={onClick} data-testid="settings-toggle">
      <ChevronDownIcon color="primary" size={3} />
    </ClickableIcon>
  </Box>
);

export interface SectionT extends Omit<BoxT, 'children' | 'onChange'> {
  children?:
    | React.ReactNode
    | ((show: boolean, setShow: (show: boolean) => void) => React.ReactNode);
  id: string;
  title?: string | undefined;
  description?: string | undefined;
  badge?: React.ReactNode | undefined;
  toggable?: boolean | undefined;
  loading?: boolean;
  toggleType?: 'button' | 'chevron';
  togglePlacement?: 'rightStart' | 'rightEnd';
  initialShow?: boolean | undefined;
  onChange?: (arg0: boolean) => void;
}

export const Section = ({
  id,
  description,
  title,
  badge,
  toggable = false,
  toggleType = 'chevron',
  loading = false,
  togglePlacement = 'rightStart',
  initialShow,
  onChange,
  children,
  onClick,
  ...props
}: SectionT) => {
  const isMobile = useMatch({ lessThan: 2 });
  const [show, setShow] = React.useState(initialShow || false);
  const augmentedOnClick =
    toggable && !loading
      ? () => {
          setShow(!show);
          if (onChange) onChange(!show);
        }
      : undefined;
  return (
    <Box width="100%" alignItems="stretch" mb="space4" {...props}>
      <Box
        onClick={onClick ? onClick : augmentedOnClick}
        mb="space2"
        justifyContent="stretch"
        flexDirection="row"
        alignItems="center"
        extend={() => ({
          cursor: toggable && !loading ? 'pointer' : undefined,
        })}
      >
        {title && loading ? (
          <Skeleton width={`${title.length}ch`} />
        ) : (
          <>
            {title && (
              <Text
                mb="space0"
                id={`${id}-label`}
                fontSize="125"
                lineHeight="heading"
                color="text"
              >
                {title}
              </Text>
            )}
            {description && (
              <TooltipDescriptive
                id={id}
                description={description}
                forceOneLine={!isMobile}
              />
            )}
          </>
        )}
        {badge}
        {augmentedOnClick && !loading && (
          <>
            {togglePlacement === 'rightStart' && <Box ml="space2" />}
            {togglePlacement === 'rightEnd' && <Box flex={1} />}
            {toggleType === 'button' ? (
              <Button
                variant="subtle"
                id={`${id}-button`}
                size="compact"
                aria-label={description}
              >
                {show ? 'Close' : 'Expand'}
              </Button>
            ) : (
              <ToggleChevron
                id={`${id}-button`}
                show={show}
                onClick={augmentedOnClick}
              />
            )}
          </>
        )}
      </Box>
      {typeof children === 'function' ? children(show, setShow) : children}
    </Box>
  );
};
