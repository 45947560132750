import type { PublicTemplatesCategoryT } from '@mentimeter/http-clients';
import { SelectItem } from '@mentimeter/ragnar-ui';

export function CategorySelect({
  selectedCategoryId,
  categories,
  onChange,
}: {
  selectedCategoryId: PublicTemplatesCategoryT['id'];
  categories: PublicTemplatesCategoryT[];
  onChange: (selectedCategory: PublicTemplatesCategoryT) => void;
}) {
  return (
    <SelectItem
      id="recommended-template-category-dropdown"
      name="template-category-dropdown"
      aria-label="Category"
      onChange={(e) => {
        e.preventDefault();

        const value = parseInt(e.target.value);
        const option = categories.find((c) => c.id === value);
        if (!option) return;

        onChange(option);
      }}
      value={selectedCategoryId}
    >
      {categories.map(({ name, id, main_usage }) => (
        <SelectItem.Option key={id} value={id}>
          {`${name} (${main_usage})`}
        </SelectItem.Option>
      ))}
    </SelectItem>
  );
}
